import React from 'react';
import './aboutUsSection.scss';

export const AboutUsSection = ({
  title,
  description,
  bgImage,
}: {
  title: string;
  description: string;
  bgImage?: string;
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
      className="about-us-section__frame"
    >
      <div className="about-us-section__content">
        <h1 className="about-us-section__title">About us</h1>

        <div className="about-us-section__text-container">
          <p>{title}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
