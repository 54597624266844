import edtech_logo from './img/logos/edtech_logo.svg';
import edtech_slide_1 from './img/slides/edtech_slide_1.svg';
import edtech_slide_2 from './img/slides/slide-edtech-2.svg';
import teambook_logo from './img/logos/teambook_logo.svg';
import teambook_slide_1 from './img/slides/teambook_slide_1.svg';
import teambook_slide_2 from './img/slides/teambook_slide_2.svg';
import gamarmarket_logo from './img/logos/gamarmarket_logo.svg';
import gamarmarket_slide_1 from './img/slides/gamarmarket_slide_1.svg';
import gamarmarket_slide_2 from './img/slides/gamarmarket_slide_2.svg';
import pinkpiko_logo from './img/logos/pinkpiko_logo.svg';
import pinkpiko_slide_1 from './img/slides/pinkpiko_slide_1.svg';
import pinkpiko_slide_2 from './img/slides/pinkpiko_slide_2.svg';
import fryingplan_logo from './img/logos/fryingplan_logo.svg';
import fryingplan_slide_1 from './img/slides/fryingplan_slide_1.svg';
import fryingplan_slide_2 from './img/slides/fryingplan_slide_2.svg';
import ruby_logo from '../../../../assets/technologies_icons/ruby.svg';
import react_logo from '../../../../assets/technologies_icons/react.svg';
import nodejs_logo from '../../../../assets/technologies_icons/nodejs.svg';
import django_logo from '../../../../assets/technologies_icons/django.svg';
import rails_logo from '../../../../assets/technologies_icons/rails.svg';
import js_logo from '../../../../assets/technologies_icons/js.svg';
import redis_logo from '../../../../assets/technologies_icons/redis.svg';
import graphql_logo from '../../../../assets/technologies_icons/graphql.svg';
import postgress_logo from '../../../../assets/technologies_icons/postgresql.svg';
import mongodb_logo from '../../../../assets/technologies_icons/mongodb.svg';
import ios_logo from '../../../../assets/technologies_icons/ios.svg';
import android_logo from '../../../../assets/technologies_icons/android.svg';
import chatgpt_logo from '../../../../assets/technologies_icons/chatgpt.svg';
import angular_logo from '../../../../assets/technologies_icons/angular.svg';
import python_logo from '../../../../assets/technologies_icons/python.svg';
import vue_logo from '../../../../assets/technologies_icons/vue.svg';
import heroku_logo from '../../../../assets/technologies_icons/heroku.svg';
import net_logo from '../../../../assets/technologies_icons/net.svg';
import bootstrap_logo from '../../../../assets/technologies_icons/bootstrap.svg';

interface RoleContent {
  role: string;
  content: string[];
}

interface INumbers {
  label: string;
  number: string;
}

export interface IDomainData {
  link: string;
  label: string;
  logo: string;
  slides: string[];
  title: string;
  content: string;
  secondLabel: string;
  technologies: string[];
  roles: RoleContent[];
  numbers: INumbers[];
}

export const domainsData: IDomainData[] = [
  {
    link: 'project-managment',
    label: 'Project Managment',
    logo: teambook_logo,
    slides: [teambook_slide_1, teambook_slide_2],
    title: 'Teambook: The tool you need to level-up your project management game',
    content:
      'Teambook SA is a Swiss company providing a web app for Project Resource Management: Teambook.  Teambook offers mid-sized professional service companies a simple and fresh way to schedule resources and projects, run mid-term capacity analysis as well as actual time tracking (time sheeting).  Teambook is intuitive, simple, and fast – so that our customer can work better, get more accomplished and focus on their business.',
    secondLabel: 'Teambook SA',
    technologies: [rails_logo, redis_logo, react_logo, postgress_logo, bootstrap_logo],
    roles: [
      {
        role: 'Software Development',
        content: [
          'Designing, developing, and implementing the Teambook web application according to project requirements and specifications.',
          'Choosing the appropriate technology stack, programming languages, and frameworks to ensure scalability, security, and performance of the app. Collaborating with stakeholders to gather requirements and iteratively develop and refine the software.',
          "Working closely with UX/UI designers to implement the app's functionality and ensure seamless user experience. Coordinating with QA testers to identify and address any issues or bugs during development.",
        ],
      },
      {
        role: 'UX/UI Design',
        content: [
          'Designing the user interface and user experience of the Teambook app to be intuitive, visually appealing, and user-friendly.',
          'Conducting user research and gathering feedback to inform design decisions. Creating wireframes, mockups, and prototypes to visualize the user journey and interactions. Iteratively refining and improving the design based on user testing and feedback.',
          'Collaborating closely with custom software developers to ensure the design vision is implemented accurately. Working with QA testers to validate the usability and effectiveness of the design through testing.',
        ],
      },
    ],
    numbers: [
      { label: 'GamarTech team members', number: '5' },
      { label: 'active platform users', number: '1.1k' },
      { label: 'months of active partnership', number: '11' },
      { label: 'tools and technologies leveraged', number: '6' },
    ],
  },
  {
    link: 'culinary',
    label: 'Culinary',
    logo: fryingplan_logo,
    slides: [fryingplan_slide_1, fryingplan_slide_2],
    title: 'FryingPlan: Plan meals effortlessly',
    content:
      'Easily plan your meals with FryingPlan! Our intuitive app lets you personalize your meal plans while keeping track of preferences, restrictions, and allergens. Simplify your cooking experience and enjoy delicious meals tailored just for you',
    secondLabel: 'FryingPlan',
    technologies: [angular_logo, django_logo, python_logo, mongodb_logo, graphql_logo],
    roles: [
      {
        role: 'Software Development',
        content: [
          'Architect the software system, outlining its structure and components to ensure scalability and efficiency.',
          'Write clean, efficient code using appropriate programming languages and frameworks to implement the desired features and functionality of FryingPlan.',
          'Work closely with product managers and designers to implement features such as meal planning, preference tracking, dietary restrictions management, and allergen alerts.',
          'Design and maintain databases to store user preferences, meal plans, and other relevant data securely.',
          'Integrate FryingPlan with external APIs or services for features like recipe databases, nutrition information, and grocery delivery services.',
          'Conduct thorough testing to identify and fix any bugs or issues, ensuring a smooth and reliable user experience.',
          'Continuously optimize the performance of FryingPlan, making improvements to speed, reliability, and resource usage.',
          'Implement robust security measures to protect user data and privacy, including encryption, authentication, and access control mechanisms.',
        ],
      },
    ],
    numbers: [
      { label: 'GamarTech team members', number: '4' },
      { label: 'active platform users', number: '900+' },
      { label: 'months of active partnership', number: '7' },
      { label: 'tools and technologies leveraged', number: '5' },
    ],
  },
  {
    link: 'health-tech',
    label: 'HealthTech',
    logo: pinkpiko_logo,
    slides: [pinkpiko_slide_1, pinkpiko_slide_2],
    title: 'Pink Piko: Make sense of every mood swing',
    content:
      'Go beyond tracking – make sense of every mood swing, every day. Pink Piko transforms mood journaling into a fun, interactive, AI-powered emotional discovery and growth journey.',
    secondLabel: 'Pink Piko',
    technologies: [react_logo, chatgpt_logo, ios_logo, android_logo, rails_logo],
    roles: [
      {
        role: 'Software Development',
        content: [
          'Translating the UI/UX designs created by the designers into code. They use HTML, CSS, and JavaScript to build the user interface components of the mood tracking application.',
          'Implementing data visualization features, such as charts or graphs, to display mood trends and patterns over time.',
          'Integrate the user interface with the backend functionality of the application, allowing users to interact with the mood tracking features seamlessly.',
          'Implement user input mechanisms, such as forms or sliders, to allow users to input their daily mood data into the application.',
          'Ensure that the application is responsive and works well on different devices and screen sizes. They also ensure cross-browser compatibility to provide a consistent experience across various web browsers.',
        ],
      },
      {
        role: 'UX/UI Design',
        content: [
          'UI/UX designers conduct user research to understand the needs, behaviors, and preferences of the target audience for the mood tracking application.',
          'Creating wireframes and prototypes of the application to visualize the layout, flow, and interaction patterns before the development phase begins.',
          'Design the visual elements of the mood tracking application, including the layout, color scheme, typography, and iconography, to create an intuitive and visually appealing user interface.',
          'Focus on creating a positive user experience by designing intuitive navigation, clear feedback mechanisms, and accessible user interface elements.',
          'Conduct usability testing to gather feedback from users and identify areas for improvement in the user interface and interaction design of the mood tracking application.',
          'Ensure that the application is accessible to users with disabilities by following accessibility best practices and guidelines, such as providing alternative text for images and ensuring keyboard navigation.',
        ],
      },
    ],
    numbers: [
      { label: 'GamarTech team members', number: '4' },
      { label: 'active platform users', number: '900+' },
      { label: 'months of active partnership', number: '18' },
      { label: 'tools and technologies leveraged', number: '7' },
    ],
  },
  {
    link: 'e-commerce',
    label: 'E-commerce',
    logo: gamarmarket_logo,
    slides: [gamarmarket_slide_1, gamarmarket_slide_2],
    title: 'Gamarmarket: Universal online trading space',
    content:
      "Gamarmarket offers a convenient platform for buyers and sellers alike to explore a diverse array of second-hand goods. Whether you're in search of unique finds or looking to declutter your space, Gamarmarket provides an accessible marketplace for discovering and trading pre-loved items. Join the community and uncover treasures waiting to be rediscovered.",
    secondLabel: '',
    technologies: [net_logo, vue_logo, postgress_logo, heroku_logo],
    roles: [
      {
        role: 'Software Development',
        content: [
          'Design and create the architecture of the Gamarmarket platform, ensuring it is scalable, efficient, and user-friendly.',
          'Develop the backend infrastructure, including databases, servers, and APIs, to support the functionality of the marketplace, such as user authentication, listing management, and transaction processing.',
          'Design and implement the user interface of the Gamarmarket website or app, ensuring a seamless and intuitive experience for buyers and sellers.',
          'Work closely with product managers and designers to implement features like search and filtering, messaging, payment processing, and user profiles.',
          'Integrate third-party services and APIs, such as payment gateways and shipping providers, to enhance the functionality of Gamarmarket and provide a seamless user experience.',
          'Conduct rigorous testing to identify and fix bugs, ensuring the stability, security, and performance of the platform.',
          'Implement security measures to protect user data and transactions, including encryption, authentication, and authorization protocols.',
          'Continuously monitor and maintain the platform, addressing any issues that arise and implementing updates and improvements based on user feedback and technological advancements.',
        ],
      },
      {
        role: 'UX/UI Design',
        content: [
          'Conducting research to understand the needs, preferences, and behaviors of both buyers and sellers in the second-hand marketplace space.',
          'Creating wireframes and prototypes of the Gamarmarket platform to outline the structure, layout, and flow of key features and functionalities.',
          'Designing the visual elements of the Gamarmarket interface, including layout, typography, color scheme, icons, and imagery, to create an intuitive and visually appealing user experience.',
          'Ensuring that the Gamarmarket platform is responsive and optimized for various screen sizes and devices, including desktops, tablets, and smartphones.',
          'Designing interactive elements and animations to enhance user engagement and provide feedback throughout the user journey.',
          'Conducting usability testing and gathering feedback from users to identify pain points, usability issues, and areas for improvement in the Gamarmarket user experience.',
          'Working closely with software developers to ensure that the UI/UX designs are implemented accurately and efficiently, and providing guidance and support during the development process.',
          'Maintaining consistency in the visual design and branding of Gamarmarket across all platforms and touchpoints to reinforce brand identity and enhance user recognition.',
          'Ensuring that the Gamarmarket platform is accessible to users with disabilities by following accessibility best practices and guidelines.',
        ],
      },
    ],
    numbers: [
      { label: 'GamarTech team members', number: '4' },
      { label: 'active platform users', number: '900+' },
      { label: 'months of active partnership', number: '18' },
      { label: 'tools and technologies leveraged', number: '7' },
    ],
  },
  {
    link: 'ed-tech',
    label: 'EdTech',
    logo: edtech_logo,
    slides: [edtech_slide_1, edtech_slide_2],
    title: 'Edulink: Empowering education, connecting communities',
    content:
      'EduLink is an innovative educational platform designed to revolutionize the learning experience for students, teachers, and parents alike. This comprehensive solution seamlessly integrates digital tools, interactive content, and personalized learning experiences to empower learners of all ages and abilities.',
    secondLabel: 'Edulink',
    technologies: [ruby_logo, js_logo, react_logo, nodejs_logo, django_logo, rails_logo],
    roles: [
      {
        role: 'Software Development',
        content: [
          'Designing and developing the EduLink platform according to project requirements and specifications. This includes building the frontend user interface, backend infrastructure, and database management system.',
          'Choosing the appropriate technology stack, programming languages, and frameworks to ensure scalability, security, and performance. Collaborating with stakeholders to gather requirements and iteratively develop and refine the software.',
          'Working closely with UX/UI designers to implement the user interface design and ensuring a seamless user experience. Coordinating with QA testers to identify and address any issues or bugs during development.',
        ],
      },
      {
        role: 'UX/UI Design',
        content: [
          'Designing the user interface and user experience of the EduLink platform to be intuitive, visually appealing, and user-friendly. This includes creating wireframes, mockups, and prototypes to visualize the user journey and interactions.',
          'Conducting user research and gathering feedback to inform design decisions. Iteratively refining and improving the design based on user testing and feedback.',
          'Collaborating closely with custom software developers to ensure the design vision is implemented accurately. Working with QA testers to validate the usability and effectiveness of the design through testing.',
        ],
      },
    ],
    numbers: [
      { label: 'GamarTech team members', number: '3' },
      { label: 'active platform users', number: '3.2k' },
      { label: 'months of active partnership', number: '28' },
      { label: 'tools and technologies leveraged', number: '11' },
    ],
  },
];
