import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { talentsReducer } from './reducers/filter.slice';
import { domainReducer } from './reducers/domains.slice';

const rootReducer = combineReducers({
  talentsReducer,
  domainReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
  });
};

export type RootState = ReturnType<typeof setupStore>;
