import React from 'react';
import './dashboardTechnologies.scss';
import { techCardsData } from './techCardsData';
import { TechCard } from './TechCards/TechCard';

const DashboardTechnologies = () => {
  return (
    <div className="dashboard-technologies__frame">
      <div className="dashboard-technologies__container">
        <h2 className="dashboard-technologies__title">Technological frameworks</h2>

        <div className="dashboard-technologies__content">
          {techCardsData.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <TechCard {...card} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardTechnologies;
