import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './index';

const AppRouter: FC = () => {
  return (
    <>
      <Routes>
        {routes.map(route => (
          <Route key={route.path} path={route.path} element={<route.component />} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default AppRouter;
