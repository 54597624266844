import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store';
import { talentsAction } from '../store/reducers/filter.slice';
import { domainAction } from '../store/reducers/domains.slice';

const actions = {
  ...talentsAction,
  ...domainAction,
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
