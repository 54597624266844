import React from 'react';
import { whyUsData } from './whyUsData';
import { Card } from '../../dashboard/dashboard_cards/Cards/Card';
import './whyUsSection.scss';

export const WhyUsSection = () => {
  return (
    <div className="why-us-section__frame">
      <div className="why-us-section__content">
        <h2 className="why-us-section__title">Why us</h2>

        <div className="why-us-section__card-container">
          {whyUsData.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <Card {...card} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
