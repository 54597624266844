export interface ICardsData {
  title: string;
  number: string;
  content: string;
}

export const whyUsData: ICardsData[] = [
  {
    title: 'Expertise',
    number: '01',
    content:
      'Our team comprises highly skilled developers and designers with years of experience in the industry. We have the expertise to tackle projects of any size and complexity',
  },
  {
    title: 'Innovation',
    number: '02',
    content:
      'We are committed to staying ahead of the curve in technological advancements. Our innovative solutions leverage the latest tools and frameworks to deliver cutting-edge results',
  },
  {
    title: 'Collaborative Approach',
    number: '03',
    content:
      'Collaboration is at the core of our process. We work closely with our clients, keeping them involved at every step of the way to ensure their vision is realized',
  },
  {
    title: 'Timely Delivery',
    number: '04',
    content:
      'We understand the importance of deadlines. Our efficient project management ensures that projects are completed on time and within budget',
  },
  {
    title: 'Transparent Communication',
    number: '05',
    content:
      'We believe in open and transparent communication. You can expect regular updates and clear, honest feedback throughout the project lifecycle',
  },
];
