import React from 'react';
import './domainsInfo.scss';

import { IDomainData } from '../../dashboard/dashboard_domains/domainsData/domainsData';

const DomainsInfo = ({ domain }: { domain?: IDomainData }) => {
  return (
    <div className="domains-info__frame">
      <div
        style={{ backgroundImage: `url(${domain?.slides[0]})` }}
        className="domains-info__image-container"
      />

      <div className="domains-info__content">
        <div className="title-container">
          <h2 className="title">{domain?.title}</h2>

          <div className="logo-container">
            <img className="logo" src={domain?.logo} alt="logo" />
            <p>{domain?.secondLabel}</p>
          </div>
        </div>

        <p className="content">{domain?.content}</p>

        <div className="technologies-container">
          {domain?.technologies.map((image, index) => {
            return <img src={image} alt={`Technology image ${index}`} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default DomainsInfo;
