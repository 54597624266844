import React from 'react';
import './domainsNumbers.scss';
import { IDomainData } from '../../dashboard/dashboard_domains/domainsData/domainsData';

const DomainsNumbers = ({ domain }: { domain?: IDomainData }) => {
  return (
    <div className="domains-numbers__frame">
      <div className="domain-numbers__container">
        <h2>Numbers</h2>

        <div className="domain-numbers__content">
          {domain?.numbers.map((item, index) => {
            return (
              <div id={`number-card-${index + 1}`} className="number-card">
                <span>{item.number}</span>

                <p>{item.label}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DomainsNumbers;
