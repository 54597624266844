export interface ICardsData {
  title: string;
  number: string;
  content: string;
}

export const cardsData: ICardsData[] = [
  {
    title: 'Planning & Analysis',
    number: '01',
    content:
      'We plan projects carefully, manage risks proactively and understand user needs for successful outcomes',
  },
  {
    title: 'Design',
    number: '02',
    content:
      'We design user-friendly systems, create seamless interfaces, and build strong technical foundations with databases, servers, and software components',
  },
  {
    title: 'Coding',
    number: '03',
    content:
      'We create software by writing and coding based on design specs, conduct thorough unit testing for component functionality, and collaborate with our team to integrate code and address any issues',
  },
  {
    title: 'Testing & Deployment',
    number: '04',
    content:
      'We thoroughly test our software, use automated tools for efficiency, and ensure a smooth deployment to production, promptly addressing any issues that arise',
  },
  {
    title: 'Maintenance & Support',
    number: '05',
    content:
      'We support and improve our software, ensuring it runs smoothly, stays secure, and evolves based on user feedback',
  },
];
