export interface ICardsData {
  title: string;
  number: string;
  content: string;
}

export const whyUsVacancyData: ICardsData[] = [
  {
    title: 'Innovative Projects',
    number: '01',
    content:
      'Work on cutting-edge solutions and contribute to projects that push the boundaries of technology, allowing you to expand your skills and knowledge',
  },
  {
    title: 'Supportive Culture',
    number: '02',
    content:
      'Experience a friendly and inclusive work environment that values collaboration, open communication, and mutual respect among team membersExperience a friendly and inclusive work environment that values collaboration, open communication, and mutual respect among team members',
  },
  {
    title: 'Growth Opportunities',
    number: '03',
    content:
      'Benefit from opportunities for professional development and advancement, including mentorship programs and access to training resources',
  },
  {
    title: 'Flexible Work',
    number: '04',
    content:
      'Enjoy a healthy work-life balance with flexible scheduling options that accommodate your individual needs and preferences',
  },
  {
    title: 'Collaborative Team',
    number: '05',
    content:
      'Join a team of talented developers and designers who work together to solve complex problems and achieve shared goals in a dynamic and creative atmosphere',
  },
];
