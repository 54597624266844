import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITalentsData, talentsData } from '../../components/talents/talentsData';
import { getHighestSalary } from '../../components/talents/utils/filerLogic';

interface IFilterChips {
  id: string;
  label: string;
  sort: string;
}

interface ITalentsState {
  talents: ITalentsData[] | undefined;
  maxSalary: number;
  filter: {
    salary_range: [any, any];
    sort_by: string;
    location: any[];
    position: any[];
    technology: any[];
    job_type: any[];
  };
  filterChips: any[];
}

const initialState: ITalentsState = {
  talents: [],
  maxSalary: getHighestSalary(talentsData),
  filter: {
    salary_range: [0, getHighestSalary(talentsData)],
    sort_by: 'none',
    location: [],
    position: [],
    technology: [],
    job_type: [],
  },
  filterChips: [],
};

const talentsSlice = createSlice({
  name: 'talents',
  initialState,
  reducers: {
    setTalents: (state, action: PayloadAction<ITalentsData[]>) => {
      state.talents = action.payload;
    },
    setMaxSalary: (state, action: PayloadAction<number>) => {
      state.maxSalary = action.payload;
    },
    setSalaryRange: (state, action: PayloadAction<[any, any]>) => {
      state.filter.salary_range = action.payload;
    },
    applySortBy: (state, action: PayloadAction<string>) => {
      state.filter.sort_by = action.payload;
    },
    applyLocation: (state, action: PayloadAction<any>) => {
      state.filter.location = action.payload;
    },
    applyPosition: (state, action: PayloadAction<any>) => {
      state.filter.position = action.payload;
    },
    applyTechnologies: (state, action: PayloadAction<any>) => {
      state.filter.technology = action.payload;
    },
    applyJobType: (state, action: PayloadAction<any>) => {
      state.filter.job_type = action.payload;
    },
    setFilterChips: (state, action: PayloadAction<any>) => {
      const { id, label, sort } = action.payload;
      const index = state.filterChips.findIndex(chip => chip.id === id);
      if (index !== -1) {
        state.filterChips = state.filterChips.map((chip, i) =>
          i === index ? { id, label, sort } : chip,
        );
      } else {
        state.filterChips = [...state.filterChips, { id, label, sort }];
      }
    },
    removeFilterChips: (state, action: PayloadAction<any>) => {
      const { id, sort } = action.payload;
      const index = state.filterChips.findIndex(chip => chip.id === id);
      if (index !== -1) {
        state.filterChips = state.filterChips.filter(chip => chip.id !== id);
      } else {
        state.filterChips = [...state.filterChips];
      }
      switch (sort) {
        case 'location':
          state.filter.location = state.filter.location.map((item: any) => ({
            ...item,
            value: false,
          }));
          break;
        case 'position':
          state.filter.position = state.filter.position.map((item: any) => ({
            ...item,
            value: false,
          }));
          break;
        case 'technology':
          state.filter.technology = state.filter.technology.map((item: any) => ({
            ...item,
            value: false,
          }));
          break;
        case 'job_type':
          state.filter.job_type = state.filter.job_type.map((item: any) => ({
            ...item,
            value: false,
          }));
          break;
        default:
          state.filter.salary_range = [0, getHighestSalary(talentsData)];
          state.filter.sort_by = 'none';
          break;
      }
    },
  },
});

export const talentsReducer = talentsSlice.reducer;
export const talentsAction = talentsSlice.actions;
