import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import CareerContent from './CareerContent/CareerContent';
import './Career.scss';

export const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useScrollPosition(setScrollPosition);

  return (
    <div className="career__main-container">
      <Header />

      <CareerContent />

      <Footer />

      <div
        className={`career-fab ${scrollPosition > 1980 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </div>
    </div>
  );
};
