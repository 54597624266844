import React, { FC, useEffect, useState } from 'react';
import { Fade, Modal } from '@mui/material';
import GamarButton from '../default_components/button/GamarButton';
import './ContactModal.scss';
import CloseIcon from '@mui/icons-material/Close';
import Api from '../../api/api';
import SuccessfulModal from './successful_modal/SuccessfulModal';

interface IContactModal {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  talentName?: string;
}

const ContactModal: FC<IContactModal> = ({ openModal, setOpenModal }) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [openNextModal, setOpenNextModal] = useState<boolean>(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (name && email && message) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [name, email, message]);

  const bookingHandler = (event: React.FormEvent<HTMLFormElement>) => {
    const subjectText = `Contact form from ${name} ${email}`;

    Api.BookACall(subjectText, message)
      .then(res => {
        setOpenModal(false);
        setOpenNextModal(true);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch(error => {
        console.error('Error booking a call: ', error);
      });
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpenModal(false);
  };

  const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <Modal open={openModal} onClick={handleClose}>
        <Fade in={openModal}>
          <div className="contact-modal-frame">
            <div className="contact-modal-content" onClick={handleContentClick}>
              <div className="contact-modal__header">
                <div className="header-row">
                  <p>Contact us</p>

                  <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setOpenModal(false)} />
                </div>

                <p>Leave us your message and we’ll get in touch with you to discuss the details</p>
              </div>

              <div className="form">
                <div className="form-row">
                  <div className="input-container">
                    <label>Your name</label>
                    <input
                      value={name}
                      onChange={e => setName(e.target.value)}
                      placeholder="Enter your name"
                      type="text"
                      required
                    />
                  </div>

                  <div className="input-container">
                    <label>Email</label>
                    <input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="yourname@example.com"
                      type="email"
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="input-container">
                    <label>How could we help you?</label>
                    <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder="Describe your project or inquiry here"
                    />
                  </div>
                </div>
              </div>

              <GamarButton
                style={window.innerWidth >= 768 ? { height: '85px' } : {}}
                label={'Send'}
                disabled={isButtonDisabled}
                onClick={bookingHandler}
              />
            </div>
          </div>
        </Fade>
      </Modal>

      <SuccessfulModal open={openNextModal} setOpen={setOpenNextModal} />
    </>
  );
};

export default ContactModal;
