import React, { FC, useState } from 'react';
import GamarButton from '../../default_components/button/GamarButton';
import './DashboardContent.scss';
import circle from '../../../assets/dashboard_img/circle.svg';
import ContactModal from '../../contact_modal/ContactModal';

const DashboardContent: FC = () => {
  const [openContactForm, setOpenContactForm] = useState(false);

  return (
    <div className="dashboard__frame">
      <div className="dashboard__container">
        <div className="dashboard__left-container">
          <div className="dashboard__text-container">
            <h1>Code Crafted, Future Delivered: <br/>
              <span className={"bold-text"}> Your Vision, <br/> </span>
              <span className={"bold-text"}>  Our <span className={"orange-text"}>Developers</span></span>
            </h1>
          </div>

          {window.innerWidth < 768 && <img className="content-circle" src={circle} alt="figure" />}

          <GamarButton
            style={{ padding: '20px' }}
            label={'Let’s discuss your project'}
            maxWidth={'300px'}
            onClick={() => setOpenContactForm(true)}
            disabled={false}
          />
        </div>

        <ContactModal openModal={openContactForm} setOpenModal={() => setOpenContactForm(false)} />

        {window.innerWidth > 768 && <img className="content-circle" src={circle} alt="figure" />}
      </div>
    </div>
  );
};

export default DashboardContent;
