import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import arrow_icon from '../../../assets/icons/Arrow-hide-down.svg';
import DomainsSidebar from '../DomainsSidebar/DomainsSidebar';
import DomainsInfo from '../DomainsInfo/DomainsInfo';

import './domainsInfoSection.scss';
import { IDomainData } from '../../dashboard/dashboard_domains/domainsData/domainsData';

const DomainsInfoSection = ({ domain }: { domain?: IDomainData }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth <= 768 ? false : true);

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  return (
    <div className="domains__frame">
      <div className="domains__content">
        <div onClick={() => window.innerWidth <= 768 && toggleSidebar()} className="domains__title">
          <h1>Domains</h1>

          <img className={`${isSidebarVisible && 'active'}`} src={arrow_icon} alt="arrow_icon" />
        </div>

        <div className="domains__info-section">
          <div className="domains__sidebar-row">
            <AnimatePresence>{isSidebarVisible && <DomainsSidebar />}</AnimatePresence>

            <DomainsInfo domain={domain} />
          </div>

          <div className="slide_2" style={{ backgroundImage: `url(${domain?.slides[1]})` }} />
        </div>
      </div>
    </div>
  );
};

export default DomainsInfoSection;
