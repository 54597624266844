import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/header_img/logo_icon.svg';
import './headerDrawer.scss';
import ContactModal from '../contact_modal/ContactModal';
import { Link } from 'react-router-dom';

const DRAWER_LINKS = [
  { text: 'About us', link: '/about' },
  { text: 'Technologies', link: '/technologies' },
  { text: 'Career', link: '/career' },
];

interface HeaderDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const HeaderDrawer = ({ open, setOpen }: HeaderDrawerProps) => {
  const [openContactForm, setOpenContactForm] = useState(false);

  const drawerStyles = {
    '.MuiBackdrop-root': {
      backdropFilter: 'blur(4px)',
      transition: 'opacity 0.8s ease, visibility 0s !important',
    },

    '.MuiDrawer-paper': {
      backgroundColor: '#f8f8f8',
      maxWidth: '320px',
      width: '100%',
      padding: '20px',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },

      '@media(max-width: 320px)': {
        width: '100%',
      },
    },
  };

  return (
    <Drawer sx={drawerStyles} anchor="right" open={open} onClose={() => setOpen(false)}>
      <div className="header__box">
        <img src={logo} alt="Gamar Logo" />

        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
      </div>

      <div className="list">
        {DRAWER_LINKS.map((obj, index) => (
          <div className="list-item">
            <Link to={obj.link}>{obj.text}</Link>
          </div>
        ))}
      </div>

      <div className="list">
        <div onClick={() => setOpenContactForm(true)} className="list-item">
          <p className="btn-text">Book a call</p>
        </div>
      </div>

      <ContactModal openModal={openContactForm} setOpenModal={() => setOpenContactForm(false)} />
    </Drawer>
  );
};
