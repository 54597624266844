import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { ITechCardsData } from '../techCardsData';

export const TechCard = (props: ITechCardsData) => {
  const [isHovered, setIsHovered] = useState(false);

  const textAnim = {
    hidden: { y: -30 },
    visible: (custom: any) => ({
      y: custom,
      transition: { delay: 0.3 },
    }),
  };

  const listAnim = {
    hidden: { opacity: 0, y: 0 },
    visible: () => ({
      opacity: 1,
      transition: { delay: 0.3, y: 0 },
    }),
  };

  const imgAnim = {
    hover: { scale: 0.5, transition: { duration: 0.3 }, y: '-25%', x: '25%' },
    initial: { scale: 1, y: 0, x: 0 },
  };

  return (
    <motion.div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      whileHover={'visible'}
      className="card-container"
    >
      <motion.img
        initial="initial"
        animate={isHovered ? 'hover' : 'initial'}
        variants={imgAnim}
        src={props.image}
        alt="Technological framework icon"
      />

      <motion.p
        initial={'hidden'}
        custom={props.custom}
        variants={textAnim}
        animate={isHovered ? 'visible' : 'hidden'}
      >
        <span>{props.coloredText}</span> {props.text}
      </motion.p>

      <motion.ul initial={'hidden'} animate={isHovered ? 'visible' : 'hidden'} variants={listAnim}>
        {props.technologies.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </motion.ul>
    </motion.div>
  );
};
