import React from 'react';
import { useParams } from 'react-router-dom';
import './vacancyInfo.scss';
import { careerVacancies } from '../../constants/careerData';
import GamarButton from '../../../default_components/button/GamarButton';

const VacancyInfo = () => {
  const { id } = useParams();

  const vacancyData = careerVacancies.find(item => item.id === id);

  const contactEmail = 'talent@gamartech.com';

  const handleEmailClick = () => {
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="vacancy__frame">
      <div className="vacancy__container">
        <h1>{vacancyData?.title}</h1>

        <div className="vacancy__info-container">
          <span>{vacancyData?.level}</span>
          <span>{vacancyData?.type}</span>
          <span>{vacancyData?.location}</span>
        </div>

        {vacancyData?.requirements?.map((condition: { title: string; items: string[] }) => {
          return (
            <div className="vacancy__condition-container">
              <p className="vacancy__condition-title">{condition?.title}</p>
              <ul className="vacancy__list-container">
                {condition?.items.map(item => (
                  <li>
                    <span className="vacancy__list-item">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}

        <GamarButton
          style={window.innerWidth >= 768 ? { height: '85px' } : {}}
          maxWidth="400px"
          label="Apply"
          disabled={false}
          onClick={handleEmailClick}
        />
      </div>
    </div>
  );
};

export default VacancyInfo;
