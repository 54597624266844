import React from 'react';
import './domainsSidebar.scss';
import {
  IDomainData,
  domainsData,
} from '../../dashboard/dashboard_domains/domainsData/domainsData';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useActions } from '../../../hooks/useActions';
import { Link } from 'react-router-dom';

const DomainsSidebar = () => {
  const domain = useSelector((state: any) => state.domainReducer.domain);
  const { setDomains } = useActions();

  const sidebarVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  };

  const onClick = (domain: IDomainData) => {
    setDomains(domain);
    localStorage.setItem('domain', JSON.stringify(domain));
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={sidebarVariants}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="domains-sidebar__container"
    >
      {domainsData.map(item => {
        return (
          <Link
            to={`/domains/${item.link.toLocaleLowerCase()}`}
            style={{ textDecoration: 'none' }}
            onClick={() => onClick(item)}
            className={`domains-sidebar__link ${
              domain.label.toLocaleLowerCase() === item.label.toLocaleLowerCase() && 'selected'
            }`}
          >
            <p>{item.label}</p>
          </Link>
        );
      })}
    </motion.div>
  );
};

export default DomainsSidebar;
