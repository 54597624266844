import React, { FC } from 'react';
import './GamarButton.scss';

interface IGamarButton {
  label: string;
  onClick: (e: any) => void;
  maxWidth?: string;
  disabled: boolean;
  style?: React.CSSProperties;
}

const GamarButton: FC<IGamarButton> = ({ label, onClick, maxWidth, disabled, style }) => {
  return (
    <button
      className="GamarButton"
      onClick={onClick}
      style={{ ...style, maxWidth: maxWidth}}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default GamarButton;
