import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/header_img/logo.svg';
import './Footer.scss';
import Api from '../../api/api';
import GamarButton from '../default_components/button/GamarButton';
import { Link } from 'react-router-dom';
import { domainsData } from '../dashboard/dashboard_domains/domainsData/domainsData';

const Footer: FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const bookingHandler = () => {
    const subjectText = `Contact form from ${name} ${email}`;

    Api.BookACall(subjectText, message)
      .then(res => {
        setSnackbarMessage(res.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity('success');
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch(error => {
        setSnackbarMessage(error?.response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity('error');
      });
  };

  useEffect(() => {
    if (name && email && message) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [name, email, message]);

  useEffect(() => {
    if (showSnackbar) {
      const timeout = setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [showSnackbar]);

  return (
    <div className="footer-frame">
      <div className="footer-content">
        <h2>Contact us</h2>

        <div className="footer-form-container">
          <div className="footer-form">
            <div className="footer-form-row">
              <div className="input-container">
                <label>Your name</label>
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Enter your name"
                  type="text"
                />
              </div>

              <div className="input-container">
                <label>Email</label>
                <input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="yourname@example.com"
                  type="text"
                />
              </div>
            </div>

            <div className="footer-form-row">
              <div className="input-container">
                <label>How could we help you?</label>
                <textarea
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Describe your project or inquiry here"
                />

                <GamarButton
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 1,
                    background: '#F8F8F8',
                    color: '#110b11',
                  }}
                  label={'Book a call'}
                  disabled={isButtonDisabled}
                  onClick={bookingHandler}
                />
              </div>
            </div>
          </div>

          <ul>
            <Link to="/about">About us</Link>
            <Link to={`domains/${domainsData[0].label.toLocaleLowerCase()}`}>Domains</Link>
            <Link to="/career">Career</Link>
          </ul>

          <ul>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </ul>
        </div>

        <Logo />
      </div>
    </div>
  );
};

export default Footer;
