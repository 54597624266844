import tech_img_1 from '../../../../src/assets/dashboard_img/tech-figure-1.svg';
import tech_img_2 from '../../../../src/assets/dashboard_img/tech-figure-2.svg';
import tech_img_3 from '../../../../src/assets/dashboard_img/tech-figure-3.svg';
import tech_img_4 from '../../../../src/assets/dashboard_img/tech-figure-4.svg';

export interface ITechCardsData {
  coloredText: string;
  text: string;
  image: string;
  technologies: string[];
  custom: number;
}

export const techCardsData: ITechCardsData[] = [
  {
    coloredText: 'web',
    text: 'application development',
    image: tech_img_1,
    technologies: [
      'Node.js',
      'React',
      'Angular',
      'Vue.js',
      '.Net',
      'Ruby on Rails',
      'Django',
      'Vanila JS',
    ],
    custom: -140,
  },
  {
    coloredText: 'technologies',
    text: '',
    image: tech_img_2,
    technologies: [
      'REST',
      'Kubenetes',
      'PostgresSQL',
      'MongoDB',
      'AWS',
      'Docker',
      'GraphQL',
      'CI/CD',
    ],
    custom: -110,
  },
  {
    coloredText: 'coding',
    text: 'languages',
    image: tech_img_3,
    technologies: ['JavaScript', 'Ruby', 'Python', 'Java', 'C#', 'Go'],
    custom: -110,
  },
  {
    coloredText: 'mobile app',
    text: 'development',
    image: tech_img_4,
    technologies: ['IOS', 'Android', 'React Native'],
    custom: -90,
  },
];
