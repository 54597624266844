import { FC } from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import { About } from '../components/about/About';
import { Career } from '../components/career/Career';
import { Vacancy } from '../components/career/vacancy/Vacancy';
import { Domains } from '../components/domains/Domains';
import { TermsAndConditions } from '../components/static_pages/TermsAndConditions';
import { PrivacyPolicy } from '../components/static_pages/PrivacyPolicy';

interface IRoutes {
  path: string;
  component: FC<any>;
}

export const Paths = {
  Dashboard: '/',
  About: '/about',
  Domains: '/domains/:id',
  Career: '/career',
  Vacancy: '/vacancy/:id',
  TermsAndConditions: '/terms-and-conditions',
  PrivacyPolicy: '/privacy-policy',
};

export const routes: IRoutes[] = [
  {
    path: Paths.Dashboard,
    component: Dashboard,
  },
  {
    path: Paths.About,
    component: About,
  },
  {
    path: Paths.Domains,
    component: Domains,
  },
  {
    path: Paths.Career,
    component: Career,
  },
  {
    path: Paths.Vacancy,
    component: Vacancy,
  },
  {
    path: Paths.TermsAndConditions,
    component: TermsAndConditions,
  },
  {
    path: Paths.PrivacyPolicy,
    component: PrivacyPolicy,
  },
];
