import React from 'react';
import './staticPages.scss';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

export const TermsAndConditions = () => {
  return (
    <div className="main-container">
      <Header />

      <div className="content-frame">
        <div className="content-container">
          <h1>Terms and Conditions</h1>

          <div className="section">
            <span className="section-title">Welcome to GamarTech!</span>
            <p>
              These terms and conditions outline the rules and regulations for the use of
              GamarTech's Website, located at <a className="link">https://gamartech.com/</a>. By
              accessing this website we assume you accept these terms and conditions. Do not
              continue to use GamarTech if you do not agree to take all of the terms and conditions
              stated on this page.
            </p>
          </div>

          <div className="additional-content-container">
            <div className="section">
              <span className="section-title">Cookies</span>
              <p>
                The website uses cookies to help personalize your online experience. By accessing
                GamarTech, you agreed to use the required cookies.
              </p>
            </div>

            <div className="section">
              <span className="section-title">License</span>
              <p>
                Unless otherwise stated, GamarTech and/or its licensors own the intellectual
                property rights for all material on GamarTech. All intellectual property rights are
                reserved. You may access this from GamarTech for your own personal use subjected to
                restrictions set in these terms and conditions.
              </p>
            </div>

            <div className="section">
              <span className="section-title">You must not:</span>
              <ul>
                <li>Republish material from GamarTech</li>
                <li>Sell, rent or sub-license material from GamarTech</li>
                <li>Reproduce, duplicate or copy material from GamarTech</li>
                <li>Redistribute content from GamarTech</li>
              </ul>
            </div>

            <p>This Agreement shall begin on the date hereof.</p>

            <p className="italic-text">
              Parts of this website offer an opportunity for users to post and exchange opinions and
              information in certain areas of the website. GamarTech does not filter, edit, publish
              or review Comments prior to their presence on the website. Comments do not reflect the
              views and opinions of GamarTech, its agents and/or affiliates. Comments reflect the
              views and opinions of the person who post their views and opinions. To the extent
              permitted by applicable laws, GamarTech shall not be liable for the Comments or for
              any liability, damages or expenses caused and/or suffered as a result of any use of
              and/or posting of and/or appearance of the Comments on this website.
            </p>

            <p>
              GamarTech reserves the right to monitor all Comments and to remove any Comments which
              can be considered inappropriate, offensive or causes breach of these Terms and
              Conditions.
            </p>

            <div className="section">
              <span className="section-title">You warrant and represent that:</span>
              <ul>
                <li>
                  You are entitled to post the Comments on our website and have all necessary
                  licenses and consents to do so;
                </li>
                <li>
                  The Comments do not invade any intellectual property right, including without
                  limitation copyright, trademark, patent or trade secret of any third party;
                </li>
                <li>
                  The Comments do not contain any defamatory, libelous, offensive, indecent or
                  otherwise unlawful material which is an invasion of privacy
                </li>
                <li>
                  The Comments will not be used to solicit or promote business or custom or present
                  commercial activities or unlawful activity.
                </li>
              </ul>
            </div>

            <p>
              You hereby grant GamarTech a non-exclusive license to use, reproduce, edit and
              authorize others to use, reproduce and edit any of your Comments in any and all forms,
              formats or media.
            </p>

            <div className="section">
              <span className="section-title">Hyperlinking to our Content</span>
              <p className="italic-text">
                The following organizations may link to our Website without prior written approval:
              </p>
              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors may link to our Website in the same manner as they
                  hyperlink to the Websites of other listed businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit organizations,
                  charity shopping malls, and charity fundraising groups which may not hyperlink to
                  our Web site.
                </li>
              </ul>
            </div>

            <p>
              These organizations may link to our home page, to publications or to other Website
              information so long as the link: (a) is not in any way deceptive; (b) does not falsely
              imply sponsorship, endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking party’s site.
            </p>

            <div className="section">
              <span className="section-title">
                We may consider and approve other link requests from the following types of
                organizations:
              </span>

              <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
            </div>

            <p>
              We will approve link requests from these organizations if we decide that: (a) the link
              would not make us look unfavorably to ourselves or to our accredited businesses; (b)
              the organization does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of GamarTech; and (d) the
              link is in the context of general resource information.
            </p>

            <p>
              These organizations may link to our home page so long as the link: (a) is not in any
              way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the
              linking party and its products or services; and (c) fits within the context of the
              linking party’s site.
            </p>

            <p>
              If you are one of the organizations listed in paragraph 2 above and are interested in
              linking to our website, you must inform us by sending an e-mail to GamarTech. Please
              include your name, your organization name, contact information as well as the URL of
              your site, a list of any URLs from which you intend to link to our Website, and a list
              of the URLs on our site to which you would like to link. Wait 2-3 weeks for a
              response.
            </p>

            <p>Approved organizations may hyperlink to our</p>

            <div className="section">
              <span className="section-title">Website as follows:</span>

              <ul>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>
                  By use of any other description of our Website being linked to that makes sense
                  within the context and format of content on the linking party’s site.
                </li>
                <li>
                  No use of GamarTech’s logo or other artwork will be allowed for linking absent a
                  trademark license agreement.
                </li>
              </ul>
            </div>

            <div className="section">
              <span className="section-title">iFrames</span>
              <p>
                Without prior approval and written permission, you may not create frames around our
                Webpages that alter in any way the visual presentation or appearance of our Website.
              </p>
            </div>

            <div className="section">
              <span className="section-title">Content Liability</span>
              <p>
                We shall not be hold responsible for any content that appears on your Website. You
                agree to protect and defend us against all claims that is rising on your Website. No
                link(s) should appear on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.
              </p>
            </div>

            <div className="section">
              <span className="section-title">Your Privacy</span>
              <p>
                Please read our{' '}
                <Link to="/privacy-policy" className="link">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>

            <div className="section">
              <span className="section-title">Reservation of Rights</span>
              <p>
                We reserve the right to request that you remove all links or any particular link to
                our Website. You approve to immediately remove all links to our Website upon
                request. We also reserve the right to amen these terms and conditions and it’s
                linking policy at any time. By continuously linking to our Website, you agree to be
                bound to and follow these linking terms and conditions.
              </p>
            </div>

            <div className="section">
              <span className="section-title">Removal of links from our website</span>
              <p>
                If you find any link on our Website that is offensive for any reason, you are free
                to contact and inform us any moment. We will consider requests to remove links but
                we are not obligated to or so or to respond to you directly.
              </p>
            </div>

            <div className="section">
              <span className="section-title">Disclaimer</span>
              <p>
                The views expressed on this website are those of the individuals and do not
                necessarily reflect the official policy or position of GamarTech. Any content
                provided by our bloggers or authors are of their opinion and are not intended to
                malign any religion, ethnic group, club, organization, company, individual or anyone
                or anything.
              </p>
            </div>

            <p>
              This website disclaimer was last updated on 14.05.2024. We reserve the right to modify
              these terms at any time. By using this website, you agree to the terms and conditions
              in effect at the time of use.
            </p>

            <div className="section">
              <span className="section-title">Contact Us</span>
              <p>
                If you have any queries regarding any of our terms, please contact us at{' '}
                <a className="link" href="mailto:sales@gamartech.com">
                  sales@gamartech.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
