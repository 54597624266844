interface ICareerVacancies {
  id: string;
  title: string;
  level: string;
  type: string;
  location: string;
  requirements: { title: string; items: string[] }[];
}

export const careerVacancies: ICareerVacancies[] = [
  {
    id: '1',
    title: 'Python Developer',
    level: 'Middle',
    type: 'Full-time',
    location: 'Remote',
    requirements: [
      {
        title: 'Experience and Background',
        items: [
          '3-5 years of professional experience in software development, with a significant focus on Python.',
          "A bachelor's degree in Computer Science, Information Technology, Engineering, or a related field (or equivalent experience).",
        ],
      },
      {
        title: 'Technical Proficiency',
        items: [
          'Strong understanding of Python programming, including core concepts and best practices.',
          'Experience with popular Python frameworks like Django, Flask, or FastAPI.',
          'Familiarity with data processing libraries such as Pandas, NumPy, and SciPy.',
          'Knowledge of database systems, including SQL (e.g., PostgreSQL, MySQL) and NoSQL (e.g., MongoDB, Redis).',
          'Experience with RESTful API design and implementation.',
          'Proficiency with version control systems, especially Git.',
          'Basic knowledge of containerization and cloud platforms like AWS, Azure, or Google Cloud Platform.',
        ],
      },
      {
        title: 'Problem-Solving and Analytical Skills',
        items: [
          'Ability to solve technical challenges and optimize code for performance.',
          'Good debugging skills and the ability to troubleshoot and resolve issues independently.',
          'Experience with writing unit tests and automated tests.',
        ],
      },
      {
        title: 'Collaboration and Communication',
        items: [
          'Strong communication skills for working with team members and stakeholders.',
          'Experience working in collaborative environments, preferably with agile or scrum methodologies.',
          'Ability to participate in code reviews and contribute to team discussions.',
        ],
      },
      {
        title: 'Soft Skills',
        items: [
          'A proactive approach to learning new technologies and improving skills.',
          'Strong organizational skills with the ability to prioritize tasks.',
          'Ability to work independently and with a team, focusing on collaboration.',
        ],
      },
      {
        title: 'Nice to Have',
        items: [
          'Knowledge of DevOps practices, including CI/CD pipelines.',
          'Experience with front-end technologies like JavaScript, HTML, and CSS is a plus.',
          'Familiarity with other programming languages or frameworks is advantageous.',
          'Exposure to cybersecurity best practices and secure coding principles.',
        ],
      },
      {
        title: 'Apply',
        items: [
          'Interested candidates should submit their applications through the provided platform.',
        ],
      },
    ],
  },
  {
    id: '2',
    title: '.NET Developer',
    level: 'Senior',
    type: 'Full-time',
    location: 'Remote',
    requirements: [
      {
        title: 'Experience and Background',
        items: [
          '3-5 years of professional experience in software development, with a significant focus on .NET technologies.',
          "A bachelor's degree in Computer Science, Information Technology, Engineering, or a related field (or equivalent experience).",
        ],
      },
      {
        title: 'Technical Proficiency',
        items: [
          'Strong understanding of .NET framework and .NET Core technologies.',
          'Experience with web development using ASP.NET MVC, ASP.NET Core, and Blazor.',
          'Proficiency in C# programming, including best practices and design patterns.',
          'Knowledge of database systems, including SQL Server, and experience with Entity Framework.',
          'Experience with RESTful API design and implementation.',
          'Familiarity with the Visual Studio development environment.',
          'Proficiency with version control systems, especially Git.',
          'Basic knowledge of containerization and cloud platforms like Azure, AWS, or Google Cloud Platform.',
        ],
      },
      {
        title: 'Problem-Solving and Analytical Skills',
        items: [
          'Ability to solve technical challenges and optimize code for performance.',
          'Good debugging skills and the ability to troubleshoot and resolve issues independently.',
          'Experience with writing unit tests and automated tests.',
        ],
      },
      {
        title: 'Collaboration and Communication',
        items: [
          'Strong communication skills for working with team members and stakeholders.',
          'Experience working in collaborative environments, preferably with agile or scrum methodologies.',
          'Ability to participate in code reviews and contribute to team discussions.',
        ],
      },
      {
        title: 'Soft Skills',
        items: [
          'A proactive approach to learning new technologies and improving skills.',
          'Strong organizational skills with the ability to prioritize tasks.',
          'Ability to work independently and with a team, focusing on collaboration.',
        ],
      },
      {
        title: 'Nice to Have',
        items: [
          'Knowledge of mobile development with Xamarin or MAUI.',
          'Experience with front-end technologies like JavaScript, HTML, and CSS is a plus.',
          'Familiarity with SignalR, or other real-time web technologies is advantageous.',
          'Exposure to DevOps practices, including CI/CD pipelines.',
        ],
      },
      {
        title: 'Apply',
        items: [
          'Interested candidates should submit their applications through the provided platform.',
        ],
      },
    ],
  },
  {
    id: '3',
    title: 'Node.js Developer',
    level: 'Middle',
    type: 'Full-time',
    location: 'Remote',
    requirements: [
      {
        title: 'Experience and Background',
        items: [
          '3-5 years of professional experience in software development, with a significant focus on Node.js.',
          "A bachelor's degree in Computer Science, Information Technology, Engineering, or a related field (or equivalent experience).",
        ],
      },
      {
        title: 'Technical Proficiency',
        items: [
          'Strong understanding of Node.js and its core principles.',
          'Experience with frameworks such as Express, Koa, or NestJS.',
          'Proficiency in JavaScript and/or TypeScript.',
          'Knowledge of asynchronous programming including callbacks, promises, and async/await.',
          'Familiarity with full stack development practices and technologies.',
          'Experience with database systems, including SQL (e.g., PostgreSQL, MySQL) and NoSQL (e.g., MongoDB).',
          'Experience with RESTful API design and implementation.',
          'Proficiency with version control systems, especially Git.',
          'Basic knowledge of containerization (Docker) and orchestration (Kubernetes) technologies.',
          'Familiarity with cloud services like AWS, Azure, or Google Cloud Platform.',
        ],
      },
      {
        title: 'Problem-Solving and Analytical Skills',
        items: [
          'Ability to solve technical challenges and optimize code for performance.',
          'Good debugging skills and the ability to troubleshoot and resolve issues independently.',
          'Experience with writing unit tests and automated tests.',
        ],
      },
      {
        title: 'Collaboration and Communication',
        items: [
          'Strong communication skills for working with team members and stakeholders.',
          'Experience working in collaborative environments, preferably with agile or scrum methodologies.',
          'Ability to participate in code reviews and contribute to team discussions.',
        ],
      },
      {
        title: 'Soft Skills',
        items: [
          'A proactive approach to learning new technologies and improving skills.',
          'Strong organizational skills with the ability to prioritize tasks.',
          'Ability to work independently and with a team, focusing on collaboration.',
        ],
      },
      {
        title: 'Nice to Have',
        items: [
          'Experience with front-end frameworks like React, Angular, or Vue.js is a plus.',
          'Familiarity with GraphQL.',
          'Knowledge of Continuous Integration and Continuous Deployment (CI/CD) practices.',
          'Experience with performance tuning and memory profiling.',
        ],
      },
      {
        title: 'Apply',
        items: [
          'Interested candidates should submit their applications through the provided platform.',
        ],
      },
    ],
  },
  {
    id: '4',
    title: 'Angular Developer',
    level: 'Senior',
    type: 'Full-time',
    location: 'Remote',
    requirements: [
      {
        title: 'Experience and Background',
        items: [
          '5+ years of professional experience in software development, with a strong focus on front-end technologies and at least 3 years specifically in Angular.',
          "A bachelor's degree in Computer Science, Information Technology, Engineering, or a related field (or equivalent experience).",
        ],
      },
      {
        title: 'Technical Proficiency',
        items: [
          'Expert understanding of Angular framework and advanced Angular features.',
          'Proficiency in TypeScript and modern JavaScript (ES6+).',
          'Deep knowledge of RxJS and state management patterns and libraries (e.g., NgRx, Akita).',
          'Experience with Angular Material and creating responsive layouts.',
          'Strong understanding of RESTful API integration and handling complex JSON structures.',
          'Experience with performance optimization in Angular applications.',
          'Proficiency with version control systems, especially Git.',
          'Knowledge of modern development tools like Node.js, npm, Webpack, and Angular CLI.',
          'Experience with testing frameworks and tools like Jasmine, Karma, and Protractor.',
        ],
      },
      {
        title: 'Problem-Solving and Analytical Skills',
        items: [
          'Ability to lead technical architecture discussions and help drive technical decisions.',
          'Strong debugging skills and the ability to troubleshoot complex issues in production environments.',
          'Ability to mentor junior developers and review code with a critical eye for improvements.',
        ],
      },
      {
        title: 'Collaboration and Communication',
        items: [
          'Strong communication skills for effectively articulating technical concepts and collaborating with team members and stakeholders.',
          'Experience leading projects and working in a senior role within a collaborative, agile environment.',
          'Demonstrated ability to work closely with UX/UI designers to implement user experiences.',
        ],
      },
      {
        title: 'Soft Skills',
        items: [
          'A proactive approach to problem-solving and a strong sense of ownership.',
          'Excellent organizational skills with the ability to manage multiple priorities effectively.',
          'Leadership qualities and the ability to inspire and mentor others.',
        ],
      },
      {
        title: 'Nice to Have',
        items: [
          'Experience with other front-end frameworks like React or Vue.js.',
          'Familiarity with server-side rendering techniques and frameworks.',
          'Experience with cloud services and deployments, especially on AWS, Azure, or Google Cloud Platform.',
          'Knowledge of CI/CD pipelines and DevOps practices.',
        ],
      },
      {
        title: 'Apply',
        items: [
          'Interested candidates should submit their applications through the provided platform.',
        ],
      },
    ],
  },
  {
    id: '5',
    title: 'Senior Java Developer',
    level: 'Senior',
    type: 'Full-time',
    location: 'Remote',
    requirements: [
      {
        title: 'Experience and Background',
        items: [
          '5+ years of professional experience in Java development.',
          "A bachelor's degree in Computer Science, Information Technology, Engineering, or a related field (or equivalent experience).",
        ],
      },
      {
        title: 'Technical Proficiency',
        items: [
          'Profound knowledge of Java and Java EE.',
          'Experience with popular Java frameworks such as Spring Boot, Hibernate, and JPA.',
          'Proficiency in building RESTful web services and SOAP APIs.',
          'Strong understanding of microservices architecture and containerized environments using Docker and Kubernetes.',
          'Experience with Maven/Gradle as build tools.',
          'Knowledge of JVM monitoring and performance tuning.',
          'Proficiency with version control systems, especially Git.',
          'Familiarity with database technology such as SQL databases (PostgreSQL, MySQL) and NoSQL databases (MongoDB, Cassandra).',
          'Experience with test-driven development (TDD) and familiar with testing frameworks like JUnit and Mockito.',
        ],
      },
      {
        title: 'Problem-Solving and Analytical Skills',
        items: [
          'Ability to tackle complex problems and engineer robust solutions to software issues.',
          'Strong debugging skills and experience in handling and resolving production issues.',
          'Ability to lead development cycles and contribute to technical planning and strategic decision-making.',
        ],
      },
      {
        title: 'Collaboration and Communication',
        items: [
          'Excellent communication skills to effectively collaborate with team members and stakeholders.',
          'Experience mentoring junior developers and leading code reviews.',
          'Proven track record of successfully managing projects in a collaborative, agile environment.',
        ],
      },
      {
        title: 'Soft Skills',
        items: [
          'A proactive approach to staying updated with the latest industry trends and technologies.',
          'Exceptional organizational skills with the ability to manage multiple tasks efficiently.',
          'Leadership skills with the ability to inspire and motivate team members.',
        ],
      },
      {
        title: 'Nice to Have',
        items: [
          'Experience with front-end development technologies such as Angular, React, or Vue.js.',
          'Familiarity with Continuous Integration and Continuous Deployment (CI/CD) processes.',
          'Knowledge of cloud platforms such as AWS, Azure, or Google Cloud.',
        ],
      },
      {
        title: 'Apply',
        items: [
          'Interested candidates should submit their applications through the provided platform.',
        ],
      },
    ],
  },
];
