import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  domainsData,
  IDomainData,
} from '../../components/dashboard/dashboard_domains/domainsData/domainsData';

interface IDomainState {
  domain: IDomainData | undefined;
}

const initialState: IDomainState = {
  domain: undefined,
};

const domainSlice = createSlice({
  name: 'domains',
  initialState: {
    domain:
      JSON.parse(localStorage.getItem('domain') || 'null') ||
      (domainsData[0] as IDomainData | undefined),
  },
  reducers: {
    setDomains: (state, action: PayloadAction<IDomainData>) => {
      state.domain = action.payload;
    },
  },
});

export const domainReducer = domainSlice.reducer;
export const domainAction = domainSlice.actions;
