import React from 'react';
import { ICardsData } from '../cardsData';
import './Card.scss';

export const Card = (props: ICardsData) => {
  return (
    <div className="dashboard__card" id={`card-${props.number}`}>
      <div className="card-header">
        <h3>{props.title}</h3>
        <span>{props.number}</span>
      </div>

      <p>{props.content}</p>
    </div>
  );
};
