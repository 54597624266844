import React, { FC, useEffect, useState } from 'react';
import './dashboard.scss';
import Header from '../header/Header';
import DashboardContent from './dashboard_content/DashboardContent';
import DashboardCards from './dashboard_cards/DashboardCards';
import Footer from '../footer/Footer';
import DashboardDomains from './dashboard_domains/DashboardDomains';
import DashboardTechnologies from './dashboard_technologies/DashboardTechnologies';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScrollPosition } from '../../hooks/useScrollPosition';

const Dashboard: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useScrollPosition(setScrollPosition);

  return (
    <div className="dashboard__main-container">
      <Header />
      <DashboardContent />
      <DashboardCards />
      <DashboardDomains />
      <DashboardTechnologies />
      <Footer />

      <div
        className={`dashboard-fab ${scrollPosition > 1980 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </div>
    </div>
  );
};

export default Dashboard;
