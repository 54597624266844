import React from 'react';
import { IDomainData } from '../../dashboard/dashboard_domains/domainsData/domainsData';
import './domainsRoles.scss';

const DomainsRoles = ({ domain }: { domain?: IDomainData }) => {
  return (
    <div className="domain-roles__frame">
      <div className="domain-roles__container">
        <h2>GamarTech's role</h2>

        <div className="domain-roles__content">
          {domain?.roles.map(role => {
            return (
              <div className="role-container">
                <h3 className="role-label">{role.role}</h3>

                <ul className="role-content">
                  {role.content.map(item => {
                    return <li className="role-item">{item}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DomainsRoles;
