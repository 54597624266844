import React from 'react';
import CareerCard from '../CareerCards/CareerCard';
import { careerVacancies } from '../constants/careerData';
import './CareerContent.scss';

const CareerContent = () => {
  return (
    <div className="career-content__frame">
      <div className="career-content__container">
        <div className="career-content__cards-container">
          {careerVacancies.map((vacancy, index) => (
            <CareerCard key={index} index={index + 1} {...vacancy} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerContent;
