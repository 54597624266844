import React from 'react';
import { whyUsVacancyData } from './whyUsVacancyData';
import { Card } from '../../../dashboard/dashboard_cards/Cards/Card';
import '../../../about/whyUsSection/whyUsSection.scss';

export const WhyUsVacancySection = () => {
  return (
    <div className="why-us-section__frame">
      <div className="why-us-section__content">
        <h1 className="why-us-section__title">Why us</h1>

        <div className="why-us-section__card-container">
          {whyUsVacancyData.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <Card {...card} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
