import React, { FC, useState } from 'react';
import './Header.scss';
import GamarButton from '../default_components/button/GamarButton';
import logo from '../../assets/header_img/logo.svg';
import logo_icon from '../../assets/header_img/logo_icon.svg';
import ContactModal from '../contact_modal/ContactModal';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate } from 'react-router-dom';
import { HeaderDrawer } from '../drawer/HeaderDrawer';
import { domainsData } from '../dashboard/dashboard_domains/domainsData/domainsData';

const Header: FC = () => {
  const [openContactForm, setOpenContactForm] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header__frame">
      <div className="header__content">
        <div onClick={() => navigate('/')} className="header__logo">
          <img src={window.innerWidth < 768 ? logo_icon : logo} alt="GamarTech Logo" />
        </div>

        <div className="header__nav-links">
          <NavLink to="/about" className="link">
            About
          </NavLink>
          <NavLink to={`/domains/${domainsData[0].label.toLocaleLowerCase()}`} className="link">
            Domains
          </NavLink>
          <NavLink to="/career" className="link">
            Career
          </NavLink>
          <NavLink target="_blank" to="https://blog.gamartech.com/" className="link">
            Blog
          </NavLink>
        </div>

        <GamarButton
          label="Book a call"
          onClick={() => setOpenContactForm(true)}
          disabled={false}
        />

        <ContactModal openModal={openContactForm} setOpenModal={() => setOpenContactForm(false)} />

        {window.innerWidth <= 768 && (
          <div onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon sx={{ cursor: 'pointer', width: 40, height: 40 }} />
          </div>
        )}

        <HeaderDrawer open={openDrawer} setOpen={setOpenDrawer} />
      </div>
    </div>
  );
};

export default Header;
