import React, { FC } from 'react';
import { Fade, Modal } from '@mui/material';
import GamarButton from '../../default_components/button/GamarButton';
import './SuccessfulModal.scss';
import icon from '../../../assets/icons/element.svg';
import CloseIcon from '@mui/icons-material/Close';

interface ISuccessfulModal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SuccessfulModal: FC<ISuccessfulModal> = ({ open, setOpen }) => {
  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className="successful-modal__frame">
          <div className="successful-modal__content">
            <div className="successful-modal__header">
              <div className="header-row">
                <p>Contact us</p>

                <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
              </div>

              <p>We will answer you soon</p>
            </div>

            <img src={icon} alt="icon" />

            <GamarButton
              label={'Back to main page'}
              onClick={() => {
                setOpen(false);
              }}
              maxWidth={'100%'}
              disabled={false}
              style={window.innerWidth >= 768 ? { height: '85px' } : {}}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SuccessfulModal;
