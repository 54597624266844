import React, { FC } from 'react';
import './DashboardCards.scss';
import { cardsData } from './cardsData';
import { Card } from './Cards/Card';

const DashboardCards: FC = () => {
  return (
    <div className="dashboard__cards-frame">
      <div className="dashboard__cards-container">
        <h2 className="dashboard__cards-title">How we work</h2>

        <div className="dashboard__cards-content">
          {cardsData.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <Card {...card} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
