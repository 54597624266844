import React, { SVGProps } from 'react';
import './CareerCard.scss';

import { ReactComponent as ArrowLinkIcon } from '../../../assets/icons/arrow-link.svg';
import { useNavigate } from 'react-router-dom';

interface CareerCardProps {
  title: string;
  level: string;
  type: string;
  location: string;
  index: number;
}

const CareerCard = ({ title, type, level, location, index }: CareerCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/vacancy/${index}`)}
      id={`card-0${index}`}
      className="career-card__container"
    >
      <span className="career-card__experience">{level}</span>

      <span className="career-card__position">{title}</span>

      <div className="career-card__additional-info-container">
        <span>{type}</span>

        <span>{location}</span>
      </div>

      <ArrowLinkIcon className="arrow-link" />
    </div>
  );
};

export default CareerCard;
