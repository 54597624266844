import React, { useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { AboutUsSection } from './AboutUsSection/AboutUsSection';
import { WhyUsSection } from './whyUsSection/WhyUsSection';
import './about.scss';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import LinesImage from '../../assets/about_img/lines.svg';

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useScrollPosition(setScrollPosition);

  return (
    <div className="about__main-container">
      <Header />
      <AboutUsSection
        title="Welcome to GamarTech, a software development company based in Georgia."
        description="With a diverse team of skilled developers and designers, we specialize in delivering
        innovative software solutions tailored to your needs. From web development to mobile
        apps and UI/UX design, we're here to bring your vision to life. Let's build something
        incredible together."
        bgImage={LinesImage}
      />
      <WhyUsSection />
      <Footer />

      <div
        className={`dashboard-fab ${scrollPosition > 1980 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </div>
    </div>
  );
};
