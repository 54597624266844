import React, { useEffect } from 'react';
import '../src/style/fonts.scss';
import AppRouter from './routes/AppRouter';
import { useLocation } from 'react-router-dom';
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <AppRouter />;
}

export default App;
