import { ITalentsData } from '../talentsData';

export const filterTalents = (talents: any, filters?: any): any => {
  if (!talents) return [];
  let filteredTalents = [...talents];

  const { sort_by, salary_range, location, technology, position, job_type } = filters || {};

  if (location?.length) filteredTalents = filterByLocation(filteredTalents, location);
  if (technology?.length) filteredTalents = filterByTechnology(filteredTalents, technology);
  if (job_type?.length) filteredTalents = filterByJob(filteredTalents, job_type);
  if (position?.length) filteredTalents = filterByExperience(filteredTalents, position);
  if (sort_by) filteredTalents = filerLogic(filteredTalents, sort_by);
  if (salary_range) filteredTalents = filterBySalaryRange(filteredTalents, salary_range);

  return filteredTalents;
};

export const getHighestSalary = (talents?: any) => {
  let highestSalary = 0;

  const talent_salary =
    talents &&
    talents.map((talent: ITalentsData) => {
      return parseInt(talent.salary.replace(/[^0-9.]/g, ''));
    });

  return talent_salary && talent_salary.length > 0 ? Math.max(...talent_salary) : highestSalary;
};

export const filterBySalaryRange = (talents: ITalentsData[], salary_range: any) => {
  return talents?.filter((talent: ITalentsData) => {
    const salary = parseInt(talent.salary.replace(/[^0-9.]/g, ''));

    const [minSalary = Number.NEGATIVE_INFINITY, maxSalary = Number.POSITIVE_INFINITY] =
      salary_range || [];

    return salary >= minSalary && salary <= maxSalary;
  });
};

export const filerLogic = (talents: ITalentsData[], appliedFilters?: any) => {
  switch (appliedFilters) {
    case 'experience_low_to_high':
      talents = talents?.sort((a: any, b: any) => parseInt(a.experience) - parseInt(b.experience));
      return talents;
    case 'experience_high_to_low':
      talents = talents?.sort((a: any, b: any) => parseInt(b.experience) - parseInt(a.experience));
      return talents;
    case 'rate_low_to_high':
      talents = talents?.sort(
        (a: any, b: any) =>
          parseInt(a.salary.replace(/[^0-9.]/g, '')) - parseInt(b.salary.replace(/[^0-9.]/g, '')),
      );
      return talents;
    case 'rate_high_to_low':
      talents = talents?.sort(
        (a: any, b: any) =>
          parseInt(b.salary.replace(/[^0-9.]/g, '')) - parseInt(a.salary.replace(/[^0-9.]/g, '')),
      );
      return talents;
    default:
      return talents;
  }
};
export const isSelectedFromFilters = (array: any[] | undefined) => {
  const tempArr: string[] = [];
  array?.map((item: any) => {
    if (item.value) {
      tempArr.push(` ${item.label}`);
    }
  });

  if (tempArr.length === 0) {
    return [];
  }
  return tempArr;
};

export const isSelectedPositionFromFilters = (position: any[] | undefined) => {
  const posArray: string[] = [];
  position?.map((pos: any) => {
    if (pos.value) {
      posArray.push(` ${pos.label.substr(0, pos.label.indexOf(' ') + 1)}`);
    }
  });

  return posArray;
};

export const filterByLocation = (talents: ITalentsData[], location: any[] | undefined) => {
  const tempArr: string[] = [];
  location?.map((item: any) => {
    if (item.value) {
      tempArr.push(item.label);
    }
  });

  if (tempArr.length === 0) {
    return talents;
  }

  return talents?.filter((talent: ITalentsData) => tempArr.includes(talent.location));
};

export const filterByTechnology = (talents: ITalentsData[], technology: any[] | undefined) => {
  const tempArr: any[] = [];
  technology?.map((item: any) => {
    if (item.value) {
      tempArr.push(item.label);
    }
  });

  if (tempArr.length === 0) {
    return talents;
  }

  return talents?.filter((talent: ITalentsData) =>
    tempArr.some((tech: string) => talent.skills.flat().includes(tech)),
  );
};

export const getRangeFromFilters = (range: number[], maxSalary: number) => {
  if (range[0] === 0 && range[1] === maxSalary) {
    return;
  }

  return `${range[0]}$ - ${range[1]}$`;
};

export const filterByJob = (talents: ITalentsData[], job: any[] | undefined) => {
  const tempArr: string[] = [];
  job?.map((item: any) => {
    if (item.value) {
      tempArr.push(item.label.toLowerCase());
    }
  });

  if (tempArr.length === 0) {
    return talents;
  }

  return talents?.filter((talent: ITalentsData) => {
    const talentPosition = talent.position.toLowerCase();
    return tempArr.some(label => talentPosition.includes(label));
  });
};

export const filterByExperience = (
  talents: ITalentsData[],
  experienceFilter: any[] | undefined,
) => {
  const experienceRanges: { [key: string]: [number, number] } = {
    junior: [0, 3],
    middle: [3, 5],
    senior: [5, Infinity],
  };

  const selectedRanges: Array<[number, number]> = [];

  experienceFilter?.forEach((item: any) => {
    if (item.value) {
      const range = experienceRanges[item.label.toLowerCase().substr(0, item.label.indexOf(' '))];
      if (range) {
        selectedRanges.push(range);
      }
    }
  });

  if (selectedRanges.length === 0) {
    return talents;
  }

  return talents.filter((talent: ITalentsData) => {
    const experience = parseInt(talent.experience, 10);
    return selectedRanges.some(([min, max]) => experience >= min && experience <= max);
  });
};
