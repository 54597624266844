import React from 'react';
import figure from '../../../assets/dashboard_img/domains-figure.svg';
import arrow_link from '../../../../src/assets/icons/arrow-link.svg';
import { IDomainData, domainsData } from './domainsData/domainsData';

import './dashboardDomains.scss';
import { Link } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';

const DashboardDomains = () => {
  const { setDomains } = useActions();

  const onClick = (domain: IDomainData) => {
    setDomains(domain);
    localStorage.setItem('domain', JSON.stringify(domain));
  };

  return (
    <div className="dashboard-domains__frame">
      <div className="dashboard-domains__container">
        <h2 className="dashboard-domains__title">Domains</h2>

        <div className="dashboard-domains__content">
          <img className="domains-figure" src={figure} alt="web development" />

          <div className="dashboard-domains__link-container">
            {domainsData.map((domain, index) => {
              return (
                <div className="link" key={index}>
                  <Link
                    onClick={() => onClick(domain)}
                    to={`/domains/${domain.link.toLocaleLowerCase()}`}
                  >
                    {domain.label}
                  </Link>

                  <img className="arrow-link" src={arrow_link} alt="arrow link" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDomains;
