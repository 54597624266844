import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = 'https://gamar-tech-json-server.onrender.com';

const Talents = {
  getAll: async () => {
    const res: AxiosResponse = await axios.get(`/talents`);

    return res;
  },

  getOne: async (id: number) => {
    const res: AxiosResponse = await axios.get(`/talents/${id}`);

    return res;
  },
};

const BookACall = async (subject: string, message: string) => {
  const res: AxiosResponse = await axios.post(`/send-email`, {
    //use your server domain
    subject: subject,
    text: message,
  });

  return res;
};

export default {
  Talents,
  BookACall,
};
