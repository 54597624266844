import React, { useEffect, useState } from 'react';
import './domains.scss';
import DomainsRoles from './DomainsRoles/DomaisRoles';
import DomainsNumbers from './DomainsNumbers/DomainsNumbers';
import DomainsInfoSection from './DomainsInfoSection/DomainsInfoSection';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useSelector } from 'react-redux';
import Loader from '../lodaer/Loader';
import { useParams } from 'react-router-dom';
import { domainsData } from '../dashboard/dashboard_domains/domainsData/domainsData';
import { useActions } from '../../hooks/useActions';

export const Domains = () => {
  const domain = useSelector((state: any) => state.domainReducer.domain);
  const { setDomains } = useActions();
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const domainFromUrl = domainsData.find(domain => domain.link === id);

    if (domainFromUrl) {
      setDomains(domainFromUrl);
      localStorage.setItem('domain', JSON.stringify(domain));
    }
  }, [id]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useScrollPosition(setScrollPosition);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="domains__main-container">
      <Header />
      <DomainsInfoSection domain={domain} />
      <DomainsRoles domain={domain} />
      <DomainsNumbers domain={domain} />
      <Footer />

      <div
        className={`domains-fab ${scrollPosition > 1200 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </div>
    </div>
  );
};
